<template>
  <div>
    <v-list-item style="margin-top: 60px;" v-if="CurrentEntity.EntityProcesses && CurrentEntity.EntityProcesses.length > 0 && Record.CanEdit" @click="AssignProcess()"  class="listoutline">
          <v-list-item-title  class="subtleoverline">
            Assign Process
          </v-list-item-title>
        <v-list-item-action>
            <v-icon class="actionicon" color="warning">mdi-swap-vertical-variant</v-icon>
          </v-list-item-action>
        </v-list-item>
    <v-dialog v-model="SelectProcessDialog" max-width="400">
    <v-card>
        <v-card-title>
        Assign Process
        </v-card-title>
        <v-select return-object @change="AssignProcessConfirm()" v-model="ActiveProcess" item-text="DisplayName" :items="CurrentEntity.EntityProcesses" label="Process"></v-select>
    </v-card>
    </v-dialog>
    <v-stepper v-model="StageID"  :dark="AppisDarkMode"  >
   <v-switch v-if="HasProcedure" class="mx-3" label="View Full Record" v-model="FullRecordView"/>
      <v-stepper-header>
        <span v-for="(stage,index) in ProcessStages" :key="stage.Number">
          <v-stepper-step complete-icon="mdi-check" :edit-icon="IsComplete(stage) ? 'mdi-check' : 'mdi-pencil'" :editable="!HasProcedure" :step="index-1+2" :complete="IsComplete(stage)" @click="updateProcessStage(stage,ActiveProcessDocid)">
            {{stage.Name}}
          </v-stepper-step>
          <v-divider></v-divider></span>          
      </v-stepper-header>     
    <v-list dense style="margin-top:-15px;" class="justify-space">
      <v-list-item class="recordtoolbar">
      <v-list-item-content >
        <v-list-item-title style="font-size: 1.25em;padding-bottom:10px;" class="mediumoverline white--text">{{ProcessStages[StageID-1] ? ProcessStages[StageID-1].Name : ''}}</v-list-item-title> 
      </v-list-item-content>
      </v-list-item>
      <v-list-item >
      <v-list-item-content >             
        <v-list-item-subtitle style="font-size: 0.825em !important;" class="subtleoverline" v-if="StageID && ProcessStages && ProcessStages[StageID-1] && ProcessStages[StageID-1].Steps">
          Checklist below
        </v-list-item-subtitle>
        <v-list-item-subtitle style="font-size: 0.825em !important;" class="subtleoverline"  v-else>
          No Checklist
        </v-list-item-subtitle>
      </v-list-item-content>
      </v-list-item>
         <ProcedureBuilder v-if="HasProcedure" :ProcessStep="ProcessStages[StageID-1]" @updateProcessStage="updateProcessStage"
          @onPhotoFileselected="onPhotoFileselected"  @onPhotoUpload="onPhotoUpload"
         :ActiveProcess="ActiveProcess" :ProcessStages="ProcessStages" :StageID="StageID" :ActiveProcessDocid="ActiveProcessDocid"
         :ProcedureSteps="ProcessStages[StageID-1].Steps" :CanEditBuild="false" :CurrentEntity="CurrentEntity"
         :Record="Record" @UpdateEditableField="UpdateEditableField" :SingleBuildTabs="ComputedSingleBuildTabs"
         :EntityCollectionRef="EntityCollectionRef" :EntityDataRef="OperationalDB"  :RelatedObj="RelatedObj"
         @ActivateUploadingSingleFileNotification="ActivateUploadingSingleFileNotification"
         @UpdateProcedureStep="UpdateProcedureStep"
       @ActivateMultipleFilesUploadNotification="ActivateMultipleFilesUploadNotification"
            :EditedElement="EditedElement" :EditedElementIndex="EditedElementIndex" style="padding-bottom:50px;"
            />
      <v-list-item>
        <v-btn dark color="warning" v-if="ProcessStages[StageID-2]"  @click="updateProcessStage(ProcessStages[StageID-2],ActiveProcessDocid)"><v-icon>mdi-chevron-double-left</v-icon>{{ProcessStages[StageID-2].Name}}</v-btn>
        <v-btn v-else disabled>Start of Process</v-btn>  
        <v-spacer></v-spacer><v-btn dark color="success" :disabled="ProcessStages[StageID-1].Steps" v-if="ProcessStages[StageID]"  @click="updateProcessStage(ProcessStages[StageID],ActiveProcessDocid)"><v-icon>mdi-chevron-double-right</v-icon>{{ProcessStages[StageID].Name}}</v-btn>
        <v-btn v-else disabled>End of Process</v-btn>
      </v-list-item>
    </v-list>
  </v-stepper>
  </div>
</template>

<script>
import firebase from 'firebase';
import db from '@/main'
import axios from 'axios'
import format from 'date-fns/format'
import ProcedureBuilder from '@/components/SuitePlugins/Pipelines/ProcedureBuilder';
export default {
    props: ['System','SystemEntities','CurrentEntity','OperationalDB','Record'],
    components: {ProcedureBuilder},
    data() {
        return {
            SelectProcessDialog: false,
            ActiveProcess: '',
            ActiveProcessDocid: '',
            ProcessStages: [],
            StageID: 1,
            StageName: '',
            UpdatingProcess: false,
            TimesheetsDialog: false,
            LiaisonDialogActive: false,
            FullRecordView: false,
            SingleBuildTabs: []
        }
    },	
    computed:{
      ComputedSingleBuildTabs(){
       let AllFields = this.CurrentEntity.AllFields
      return this.SingleBuildTabs.map(tab => {
        let elementsprop = ''
        if(tab.Elements){
          elementsprop = 'Elements'
        }
        else{
          elementsprop = 'Sections'
        }
        //alert(elementsprop)
        if(tab[elementsprop]){
          tab[elementsprop] = tab[elementsprop].map(section => {
            //console.log(section)
          if(section.Fields){
            section.Fields = section.Fields.map(field => {
             
              let fieldobj = AllFields.find(obj => obj.id === field.Name)
              //console.log('AllFields,field.Name',AllFields,field.Name,fieldobj)
              if(fieldobj && fieldobj.Type === 'Lookup' && fieldobj.RelatedBuildID !== 'Users'){
                  //alert(field.Name+'Array')
                  //console.log('Lookup',fieldobj.RelatedRecord.split(' ').join('')+'Array',this.$store.state[fieldobj.RelatedRecord+'Array'],this.$store.state)
                  fieldobj.Array = this.$store.state[fieldobj.RelatedRecord.split(' ').join('')+'Array']
                  if(fieldobj.IsMultiple){
                    fieldobj.Array = fieldobj.Array.map(item => {                      
                            let itemobj = {
                            [fieldobj.LookupFieldName]: item[fieldobj.LookupFieldName],
                            id: item.id
                            }
                            return itemobj
                        })
                    }
                }
                else if(fieldobj && fieldobj.Type === 'Lookup' && fieldobj.RelatedBuildID === 'Users'){
                  //alert(field.Name+'Array')
                  //console.log('Lookup','Users',this.$store.state['Users'],this.$store.state)
                  fieldobj.Array = this.$store.state['Users']
                }
                if(!fieldobj){
                  console.log('missing field ',field)
                  fieldobj = field
                }
              return fieldobj
            })
          }
          //prepare updated permissionname Props just prior to GetCabinetDocuments
          //CustomRolesDefault
          //console.log(section.Name,section.DataViewName,section,this.$store.state)
          if(section.DataViewName === 'Data Single Document Register' || section.DataViewName === 'Data Single Gallery'){
          //  this.CustomRolesDefault.map(defrole => {
          //    console.log(defrole.Prop,section.Name,section[defrole.Prop])
          //  })
            if(section.CreatePermission === 'Inherited'){
              section.CanCreate = this.CanEdit
            }
            else if(section.CreatePermission === 'Custom Role'){
              section.CustomCreateRoles.map(role => {
                if(this.UserRoles.includes(role)){
                  section.CanCreate = true
                }
              })
            }
            else if(section.CreatePermission === 'Admin'){
              section.CanCreate = this.userIsAdmin
            }
            if(section.GetPermission === 'Inherited'){
              section.CanGet = true
            }
            else if(section.GetPermission === 'Custom Role'){
              section.CustomGetRoles.map(role => {
                if(this.UserRoles.includes(role)){
                  section.CanGet = true
                }
              })
            }
            else if(section.GetPermission === 'Admin'){
              section.CanGet = this.userIsAdmin
            }
            if(section.ListPermission === 'Inherited'){
              section.CanList = true
            }
            else if(section.ListPermission === 'Custom Role'){
              section.CustomListRoles.map(role => {
                if(this.UserRoles.includes(role)){
                  section.CanList = true
                }
              })
            }
            else if(section.ListPermission === 'Admin'){
              section.CanList = this.userIsAdmin
            }
            if(section.UpdatePermission === 'Inherited'){
              section.CanEdit = this.CanEdit
            }
            else if(section.UpdatePermission === 'Custom Role'){
              section.CustomUpdateRoles.map(role => {
                if(this.UserRoles.includes(role)){
                  section.CanEdit = true
                }
              })
            }
            else if(section.UpdatePermission === 'Admin'){
              section.CanEdit = this.userIsAdmin
            }
            if(section.DeletePermission === 'Inherited'){
              if(this.CurrentEntity.Security_Structure && this.CurrentEntity.Security_Structure.Name === 'Custom Roles'){
                section.CanDelete = this.$store.state[this.CurrentEntity.DisplayName.split(' ').join('')+'CanDelete']
              }
              else if(this.CurrentEntity.Security_Structure && this.CurrentEntity.Security_Structure.Name === 'Business Unit'){
               if(this.deleteAll){
                  section.CanCreate =  true
                }
                else if(this.deleteUnitdown){
                   if(this.userBUChildren.includes(this.DynamicRecordData.Business_Unitid)){
                    section.CanCreate = true  
                    }
                }
                else if(this.deleteUserUnit){
                  if(this.DynamicRecordData.Business_Unitid === this.userBUID){
                    section.CanCreate = true  
                    }
                }
                else if(this.deleteOwner){
                  if(this.DynamicRecordData.Ownerid === this.UserRecord.id){
                    section.CanCreate = true  
                    }
                }
              }
            }
            else if(section.DeletePermission === 'Custom Role'){
              section.CustomDeleteRoles.map(role => {
                if(this.UserRoles.includes(role)){
                  section.CanDelete = true
                }
              })
            }
            else if(section.DeletePermission === 'Admin'){
              section.CanDelete = this.userIsAdmin
            }
          }
          else if(section.DataViewName === 'Data Single SubCollection'){
            //console.log(section)
            let subcol = this.SubCollections.find(obj => obj.id === section.Entityid)
            section.CanCreate = this.CanEdit
            section.CanDelete = this.CanDelete
            section.CanEdit = this.CanEdit

          }
          // if(this.SelectedElement && this.SelectedElement.DisplayName !== section.DisplayName){
          //   section.ShowComponents = false
          // }
          return section
        })
        // .filter(elmnt => {
        //   return elmnt.ShowComponents
        // })
        }
         //tab.ShowComponents = false
        return tab
      }).filter(tab => {
        return tab.Elements.length > 0
      })
      
    },
        UserRoles(){
          return this.userLoggedIn.rolesarrayQuery
        },
        HasProcedure(){
        return this.ActiveProcess && this.StageID && this.ProcessStages && this.ProcessStages[this.StageID-1].Steps && this.ProcessStages[this.StageID-1].Steps.length > 0 && !this.UpdatingProcess
        },
        UsersArray(){
        return this.$store.state.UsersArray
        },
        userLoggedIn () {
        return this.$store.getters.user
        },
        UserisGuest(){
            return this.userLoggedIn && this.userLoggedIn.IsGuestUser     
        },
        userIsEagleViewer () {
            return this.$store.state.IsEagleView
        },
        userIsAdmin () {
            return this.$store.state.IsAdmin
        },
        userBUID () {
            return this.$store.getters.userBUID
        },
        userBUChildren () {
            return this.$store.getters.userBUChildren
        },
        userBUParents () {
            return this.$store.getters.userBUParents
        },
        WindowHeight(){
            return window.innerHeight
        },
        SocialItemInteractMethod(){
        return this.$store.state.SocialItemInteractMethod
        },
        SocialItemInteractMethodProp(){
            return this.$store.state.SocialItemInteractMethodProp
        },
    },
    watch: {     
      CurrentEntity:{
         handler: function(oldvalue, newvalue) {
                if(oldvalue !== newvalue){
                 this.SingleBuildTabs = this.CurrentEntity.SingleBuildTabs
                }
         },
            deep: true
      },   
      StageID:{
         handler: function(oldvalue, newvalue) {
                if(oldvalue !== newvalue){
                  this.UpdatingProcess = true
                  setTimeout(() => {
                    this.UpdatingProcess = false
                  }, 100);
                }
         },
            deep: true
      },   
        Record: {
          handler: function(newvalue, oldvalue) {
              if(newvalue !== oldvalue){
                if(newvalue.ActiveProcessDocid && newvalue.ActiveProcessDocid !== oldvalue.ActiveProcessDocid || 
                newvalue.stagenameid && newvalue.stagenameid !== oldvalue.stagenameid){
                  this.GetProcessData(newvalue)
                }
                else{                  
                  this.ProcessStages = []
                  this.ActiveProcess = ''
                  this.ActiveProcessDocid = ''
                  this.StageID = 1
                  this.StageName = ''
                }
                
              }
          },deep: true
        },
        SocialItemInteractMethod(v){   
        let acceptedmethods = []   
        if(v && acceptedmethods.includes(v)){
          if(this.SocialItemInteractMethodProp){
            this[this.SocialItemInteractMethod](this.SocialItemInteractMethodProp)
            if(this.SocialItemInteractMethod === 'ActivateSelectedElement'){
              this.SingleRecordNavMenuItem = ''
            }
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }
          else{ 
            this[this.SocialItemInteractMethod]()
            this.$store.commit('SetSocialItemInteractMethodProp','') 
            this.$store.commit('SetSocialItemInteractMethod','')
          }          
        }
      },
    },
    created(){
      if(this.Record && this.Record.ActiveProcessDocid){
        this.GetProcessData(this.Record)
      }
      this.SingleBuildTabs = this.CurrentEntity.SingleBuildTabs
    },
    methods:{
      UpdateProcedureStep(n){
      //StageID-1
      //stagenameid
      this.OperationalDB.collection('processes').doc(this.ActiveProcessDocid).update({
        [this.Record.stagenameid+'Step']: n
      })
    },
        AssignProcess(){
      this.SelectProcessDialog = true
    },
      AssignProcessConfirm(){
      confirm ('this will activate the process '+this.ActiveProcess.Name) && this.ProcessActivate()
    },
    ProcessActivate(){
      this.OperationalDB.update({
        ActiveProcess: this.ActiveProcess
      })
      let processdoc = 'RecordProcess'+this.ActiveProcess.Number
      //console.log(processdoc)
      //console.log(typeof this.Record[processdoc],this.Record[processdoc])
      //console.log(!this.Record[processdoc])
      if(!this.Record[processdoc]){
        // let ProcessDoc = 
        this.OperationalDB.collection('processes').add(this.ActiveProcess).then(doc => {
          this.OperationalDB.update({
            [processdoc]: doc.id,
            ActiveProcessDocid: doc.id
          })
            let Stage = this.ActiveProcess.ProcessStages[0]
            this.updateProcessStage(Stage,doc.id)
        })
       
        this.SelectProcessDialog = false
      }
      else{
        this.OperationalDB.collection('processes').doc(this.Record[processdoc]).update({
         DisplayName: this.ActiveProcess.DisplayName,
         Name: this.ActiveProcess.Name,
         ID: this.ActiveProcess.Number,
          ProcessStages: this.ActiveProcess.ProcessStages
        })
        this.OperationalDB.update({
            ActiveProcessDocid: this.Record[processdoc]
          })
          this.ActiveProcessDocid = this.Record[processdoc]
          //console.log(this.OperationalDB.collection('processes').doc(this.ActiveProcessDocid))
          this.OperationalDB.collection('processes').doc(this.ActiveProcessDocid).get().then(stagesnap => {
            var stagedata = stagesnap.data()
            if(stagedata){
            this.StageName = stagedata.StageName
            if(stagedata.StageID){
            let stageobj = this.ActiveProcess.ProcessStages.find(obj => obj.Number === stagedata.StageID)
            //console.log('stageobj',stageobj,this.ActiveProcess,this.ActiveProcess.ProcessStages.indexOf(stageobj))
            this.StageID = this.ActiveProcess.ProcessStages.indexOf(stageobj)-1+2
            this.updateProcessStage(stageobj,this.ActiveProcessDocid)            
            }
            }
            this.SelectProcessDialog = false
            })
          }
    },
    updateProcessStage(Stage,ActiveProcessDocid){
          setTimeout(() => {
          this.OperationalDB.collection('processes').doc(ActiveProcessDocid).update({
            StageName: Stage,
            StageID: Stage.Number,
                  Modified_By: {Full_Name: this.userLoggedIn.Full_Name,id: this.userLoggedIn.id},
                  Modified_Byid: this.userLoggedIn.id,
                  Modified_On: new Date()
          })
          this.OperationalDB.update({
            stagename: Stage,
            stagenameid: Stage.Number,
          })
          
        }, 300)
        },
        
    IsComplete(Stage){
      if(this.StageID && this.ProcessStages.length > 0){
        //console.log('this.ProcessStages this.StageID',this.ProcessStages,this.StageID)
      return this.ProcessStages[this.StageID-1].Number >= Stage.Number  
      }
      
    },
    UpdateStages(Prop,value){
      this.OperationalDB.collection('processes').doc(this.ActiveProcessDocid).update({
        [Prop]: value
      })
    },
        GetProcessData(){
              //console.log('GetProcessData')
              let ProcessObj = this.CurrentEntity.EntityProcesses.find(obj => obj.id == this.Record.ActiveProcess.id)
              if(ProcessObj){
                this.ActiveProcessDocid = this.Record.ActiveProcessDocid            
                //console.log('ProcessObj',ProcessObj)
                this.ProcessStages = JSON.parse(JSON.stringify(ProcessObj.ProcessStages))
              if (typeof this.Record.stagenameid !== 'undefined'){
                let stageobj = this.ProcessStages.find(obj => obj.Number === this.Record.stagenameid)
                this.StageID = this.ProcessStages.indexOf(stageobj)-1+2
              }
              this.OperationalDB.collection('processes').doc(this.ActiveProcessDocid).get().then(stagesnap => {
                    var stagedata = stagesnap.data()    
                    //console.log('stagedata',stagedata,this.ProcessStages)                
                    this.ActiveProcess = stagedata
                    this.StageName = stagedata.StageName
                    if(this.StageName){
                      let stageobj = this.ProcessStages.find(obj => obj.Number === this.Record.stagenameid)
                      //console.log('stageobj',stageobj) 
                    this.StageID = this.ProcessStages.indexOf(stageobj)-1+2
                    this.ProcessStages = this.ProcessStages
                    .map(stage => {
                      if(stage.Steps && stage.Steps.length>0){
                        stage.Steps = stage.Steps.map(step => {
                          if(typeof stagedata[step.Name] !== 'undefined'){
                            step.Prop = stagedata[step.Name]
                          }
                          return step
                        })
                      }
                      return stage
                    })
                  }
                })
              }
              
    },
        
    }
}
</script>

<style>

</style>



